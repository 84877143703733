<template>
    <div v-if="show">

        <!--liste des boutons -->
        <v-btn class="primary" :class="datas.button.class" v-for="(mail,i) in listMails" :key="i" @click="SelectMail(mail)"><v-icon>mdi-email-outline</v-icon> {{ mail.titre }}</v-btn>
        <!-- Liste des attente d'envoi d'email-->
        <div v-for="(pending,p) in mailPending" :key="'s'+p">
            <div v-if="pending.group.includes(params.id)">
                {{ listMails[pending.selectedMail.id].titre }}
                <span v-if="moment(pending.planified).isBefore(moment())"> en cours d'envoi (restant : {{ pending.group.length }}/{{ pending.total }})</span>
                <span v-else>, {{ pending.total }} mails planifiés le {{ moment(pending.planified).format('DD-MM-YYYY à HH:mm') }}</span>
                <v-btn x-small @click="annulSend(pending)">Annuler l'envoi</v-btn> - 
                <v-btn x-small @click="annulSend(pending,true)">Annuler l'envoi groupé</v-btn>
            </div>
        </div>

        <v-dialog
        :retain-focus="false"
        width="50%" 
        v-model="dialog"
        :fullscreen="$vuetify.breakpoint.xs">
        <OverLay/>
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    Envoi de l'email
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog()"><v-icon>mdi-close-thick</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <!-- nom de l'expéditeur et liste des pièces jointes -->
                <p class="text-center text-h6">Expéditeur : {{ params.mailfrom.name }} ( {{ params.mailfrom.from }} )</p>

                Pièces jointes :
                    <div v-if="params.attachements.length && params.selectedMail.id">
                        <div v-for="(attach,i) in params.attachements" :key="'p'+i" :class="['rounded pa-2 grey lighten-3 dark',{'text-decoration-line-through':!params.includeAttachement[params.selectedMail.id][attach.mod]}]">
                            <v-btn icon @click="includePJFile(attach,false)" v-if="params.includeAttachement[params.selectedMail.id][attach.mod]" class="red--text"><v-icon>mdi-close-thick</v-icon></v-btn>
                            <v-btn icon @click="includePJFile(attach,true)" v-if="!params.includeAttachement[params.selectedMail.id][attach.mod]" class="green--text"><v-icon>mdi-refresh</v-icon></v-btn>
                            {{ attach.name }}
                        </div>
                    </div>
                    <div v-for="(file,i) in $store.getters['getGedFile']" :key="'g'+i" class="rounded pa-2 grey lighten-3 dark">
                        <v-btn icon @click="$store.dispatch('removeGedFile',file)" class="red--text"><v-icon>mdi-close-thick</v-icon></v-btn> 
                        {{ file.path.split("/").pop() }} (GED)
                        </div> 

                            <div v-if="isGed.id">
                                <v-btn @click="gotoGed" icon class="primary dark"><v-icon color="white">mdi-file-download-outline</v-icon></v-btn>
                                <v-dialog
                                :retain-focus="false"
                                width="50%" 
                                v-model="dialogged"
                                @click:outside="$store.dispatch('removeAllGedFile')"
                                :fullscreen="$vuetify.breakpoint.xs">
                                <v-card>
                                    <v-card-title class="text-h6 grey lighten-2">
                                        Documents
                                    <v-spacer></v-spacer>
                                    <v-btn icon @click="closeGed"><v-icon>mdi-close-thick</v-icon></v-btn>      
                                    </v-card-title>
                                <ged-component v-if="dialogged" :idmodule="isGed.id" :page="page" :modal="modal" :parent="parent" :customparams="isGed.params"/>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn @click="dialogged=false">intégrer</v-btn>
                                </v-card-actions>
                                </v-card>
                                </v-dialog>
                            </div>





                <v-tabs v-model="tab">

                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#indiv">Individuel</v-tab>
                <v-tab href="#group">envoi groupé</v-tab> 

                <v-tabs-items v-model="tab" class="pa-2">

                <v-tab-item id="indiv" value="indiv" >
                <!-- début de l'envoi individuel-->
                <v-row>
                    <v-col>
                        
                        <ArrayTable 
                            :item="{otherparams:{
                                arrayTable:[
                                    {type:'',key:'Name',name:'alias'},                           
                                    {type:'',key:'Email',name:'adresse e-mail'}]}
                            ,defaultprops:{show:true,tableDraggable:false,tableDel:false,hideFooter:true,hidesearch:true,addTitle:'ajouter une adresse'}}"
                            :valField="params.emails"
                            :change="(item,model)=> { changeParams(item,model,'emails') }"
                            />

                    <TextField
                    :item="{ name: 'Objet', value: 'objet' ,defaultprops:{ show: true }}"
                    :valField="params.selectedMail.objet"
                    :keyup="(item,model)=> { changeMail(item,model,'objet') }"
                    /> 
                            
                        <div v-if="fieldForUpdate">
                            <fromBDD 
                            :item="{fromBDD:fieldForUpdate,defaultprops:{show:true}}"
                            :page="page"
                            :valField="params.selectedMail.corp"
                            :keyup="(item,model)=> { changeMail(item,model,'corp') }"
                            /> 
                        </div>
                        <div v-else>
                            <texteditor
                            :page="page"
                            :item="{name: 'corp du mail',value: 'corp',
                            
                            defaultprops:{ show: true}
                            }"
                            :keyup="(item,model)=> { changeMail(item,model,'corp') }"
                            :valField="params.selectedMail.corp"
                            />
                        </div>

                    </v-col>

                    </v-row>
                    <v-row>
                        <v-col class="col-12 col-md-3">

                            <v-btn @click="send">envoyer</v-btn> 

                        </v-col>
                    </v-row>
                    <!-- fin de l'envoi individuel-->
                    </v-tab-item>





                    <!-- début de l'envoi groupé-->




                    <v-tab-item id="group" value="group">
                        <v-row>
                            <v-col>
                                <p>L'envoi groupé est personnalisé par les pièces jointes que vous avez choisi, et l'objet du mail.</p>
                                <p>Le texte ainsi que les expéditeurs seront selectionnés selon le paramétrage par défaut.</p>
                                <p align="center"><b>{{ selectedGroup.length }} selection(s)</b></p>

                                <p>
                                    <SwitchC
                                    :item=" { name: 'Autoriser l\'envoi aux personnes qui sont en statut \'envoyé\' ou \'en cours\'.',value: '',liste: [{ text: 'OUI', value: true },{ text: 'NON', value: false }],
                                        defaultprops:{ show: true, column: false }}"
                                    :change="(item,model) => { removeOrAllowAll = model;  }"
                                    :valField="removeOrAllowAll"
                                    />            
                                </p>
                                
                                <SimpleTable :key="reload" :Headers="listGroup.headers" :datas="listGroup.liste" :selected="selectedGroup" keyRow="id"
                                @getselected="(val) =>{ selectedGroup = val}" :hidefooter="true" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-12 col-md-3">
                                <Radio 
                                    :change="(item,value) => { isplann = value }"
                                    :valField="isplann"
                                    :item="{name:'Envoi ',value:'isplann',liste:[{text:'Maintenant',value:'now'},{text:'Planifier',value:'after'}],
                                    defaultprops:{show:true, column:false, class:'pa-0 ma-0' }}"
                                    />  

                                <dateTimePicker
                                v-if="isplann == 'after' "
                                    :item="{ name: 'Planifier le', value: 'planified' ,defaultprops:{ show: true}}"
                                    :valField="params.planified"
                                    :change="(item,model)=> { params.planified = model }"
                                    /> 

                                <v-btn @click="sendgroup">envoyer</v-btn>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    </v-tabs-items>
                    </v-tabs>

                    </v-card-text>
                </v-card>

            </v-dialog>
        </div>
</template>
        
        
        
<script>
import fromBDD from '../fields/fromBDD.vue'
export default {

    name: "sendEmail",

    props: ['idmodule', 'page', 'modal', 'parent', 'hook','customparams','customquerys'],

    components: {
        texteditor : () => import("../fields/texteditor.vue"),
        TextField: () => import("../fields/textfield.vue"),
        SwitchC: () => import("../fields/switch.vue"),
        dateTimePicker: () => import("../fields/dateTimePicker.vue"),
        ArrayTable: () => import("../fields/arrayTable.vue"),
        OverLay: () => import('../outils/overlay.vue'),
        SimpleTable: () => import("../engine/outils/simpleTable.vue"),
        Radio    : () => import('../fields/radio.vue'),
        fromBDD
    },

    data: function () {

        return {
            tab : '',
            AllowedDisplay: true,
            show: false,
            datas: {},
            isplann : 'now',
            params: { selectedMail : {},emails : [], send:false, attachements : [], mailfrom : {},includeAttachement : {}, planified : new Date() },
            querys: {},
            dialog: false,
            dialogged : false,
            fieldForUpdate : false,
            listMails : [],
            listGroup : {liste:[],headers:[],'mailwaiting':[],'mailsent':[]},
            selectedGroup:[],
            //liste des groupes en attente d'envoi
            mailPending : [],
            //liste des id en attente d'envoi en fonction du mail selectionné
            selWaiting : [],
            //liste des id deja envoyés en fonction du mail selectionné
            selSent : [],
            //permet de switch si possibilité d'envoi à tte les pers. meme encours ou attente, ou non
            removeOrAllowAll : false,
            reload : 0,
            isGed: {},
            typePage : (!this.$store.getters['listForm'](this.page)) ? 'page' : this.$store.getters['listForm'](this.page).typeform,
        }
    },

    computed : {
            modEditForm() {
                return this.$store.getters['listForm'](this.page);
            }
    },

    watch: {

        'modEditForm.typeform': {
                // permet de rafraichir quand la modal de modif du module de la page se ferme
                handler(newVal,oldVal) {
                    if(oldVal!='new') return;
                        this.typePage = newVal
                        this.initiateParams()
                        this.getModule()
                },deep:true,
            },
            
            dialog(val) {
                if(val) this.reload++;
            },

            removeOrAllowAll(val) {
                if(val) {
                    this.listGroup.liste.forEach((v,i) => {
                    this.listGroup.liste[i]['isSelectable'] = true;                    
                    })
                } else {
                    this.listGroup.liste.forEach((v,i) => {
                    var isSelectable = true;
                    if(this.selWaiting.includes(v.id) || this.selSent.includes(v.id)) {  
                        isSelectable = false; 
                        this.selectedGroup = this.selectedGroup.filter(num => num !== v.id);
                    }
                    this.listGroup.liste[i]['isSelectable'] = isSelectable;
                    })
                }
            this.reload++
            }
    },

    methods: {

        getModule: function () {
            if(this.typePage=='new') return
            this.API({ type: 'post', url: 'displaymodule', params: { id: this.idmodule, params: this.params, querys: this.querys } }).then((resp) => {
                this.datas = resp.data;
                if (!resp.data.success) {
                    this.AllowedDisplay = false;
                    this.$toast.error(resp.data.message)
                    return false;
                }
                this.listMails = resp.data.listmails;
                
                    this.params.emails = resp.data.emails
                    this.params.mailfrom = resp.data.mailfrom
                    this.params.attachements = resp.data.attachements
                    this.params.includeAttachement = resp.data.includeAttachement
                    this.isGed = resp.data.ged;
                    this.mailPending = resp.data.mailpending;
                    if(resp.data.group) this.listGroup = resp.data.group
                    if(resp.data.idField) this.fieldForUpdate = resp.data.idField                


                this.show = true

                if(this.querys.selected && this.listMails[this.querys.selected]) {

                    this.SelectMail(this.listMails[this.querys.selected]);
                }

            })

        },

        changeParams(item, value, type) {
            this.params[type] = value
        },

        changeMail(item, value, type) {
            this.params.selectedMail[type] = value
        },

        send() {
            //si c'est un envoi de groupe on va d'abord envoyer les intructions pour préparer l'envoi

            this.$store.dispatch('textOverlay','Envoi du mail en cours');
            this.$store.dispatch('getoverlay', true)
            this.params.send = true
            this.API({ type: 'post', url: 'displaymodule', params: { id: this.idmodule, params: this.params, querys: this.querys, gedfiles:this.$store.getters['getGedFile'] } }).then((resp) => {
                this.$store.dispatch('getoverlay', false)

                if (!resp.data.success) {
                    this.$toast.error(resp.data.message)
                    return false;
                }
                this.$toast.success("l'email est bien envoyé !")
                this.dialog = false;
                this.$store.dispatch('removeAllGedFile');
                })            
        },


        sendgroup() {
            if(this.selectedGroup.length==0) {
                this.$toast.info('Veuillez selectionner au moins une ligne') 
                return false;
            }
            if(this.isplann=='after' && this.moment() > this.moment(this.params.planified)) {
                this.$toast.info("La date d'envoi doit etre supérieure à la date courante"); 
                return false;
            }

            


            if(confirm('Vous allez programmer '+this.selectedGroup.length+' mails, confirmer cette action ?')) {
            this.$store.dispatch('textOverlay',"Préparation de l'envoi groupé");
            this.$store.dispatch('getoverlay', true)            

            this.API({ type: 'post', url: 'displaymodule', params: { group : this.selectedGroup, id: this.idmodule, params: this.params, querys: this.querys, gedfiles:this.$store.getters['getGedFile'] }}).then((resp) => {
                this.$store.dispatch('getoverlay', false)

                if (!resp.data.success) {
                    this.$toast.error(resp.data.message)
                    return false;
                } else {
                    this.$toast.success(resp.data.message)
                }
                this.dialog = false;
                this.$store.dispatch('removeAllGedFile');
                this.selectedGroup = []
                this.getModule();
                
                }) 
            }

        },

        annulSend(email,group) {
            this.$store.dispatch('textOverlay',"Suppression de l 'envoi");
            this.$store.dispatch('getoverlay', true) 
            this.API({ type: 'post', url: 'displaymodule', params: { annulsend : email.key, idannul : this.params.id ,allcampaign : group, id: this.idmodule, params: this.params}}).then((resp) => {
                this.$store.dispatch('getoverlay', false)

                if (!resp.data.success) {
                    this.$toast.error(resp.data.message)
                } else {
                    this.$toast.success(resp.data.message)
                }
                this.getModule();
                this.$store.dispatch('getoverlay', false)
                })
        },


        initiateParams() {
        var modal = this.$store.getters.modalShowParams({ type: 'to', val: this.page })
        let params = (this.modal && modal.show) ? modal.params : this.$route.params
        params = (this.customparams) ? this.customparams : params;
        this.params = {...this.params, ...params}

        this.querys = (this.modal && modal.show) ? modal.query : this.$route.query
            
        this.querys = (this.customquerys) ? this.customquerys : this.querys;
        if (this.hook == 'search') this.querys.global = this.$store.getters.getStringSearch;
        },

        gotoGed() {
            this.isGed.params.select = true;
            this.dialogged = true;
        },

        closeGed() {
            this.$store.dispatch('removeAllGedFile'); 
            this.dialogged=false;
        },

        SelectMail(mail) {

            this.params.selectedMail = mail
            // on récupère les id qui sont déjà envoyé ou qui sont en attente pour les disabled de la liste
            if(this.listGroup.mailwaiting[mail.id]) this.selWaiting = this.listGroup.mailwaiting[mail.id]
            if(this.listGroup.mailsent[mail.id]) this.selSent = this.listGroup.mailsent[mail.id]
            // on ajout le nom de l'état pour chaque ligne qui etst sujet à un etat
            this.listGroup.liste.forEach((v,i) => {
                var isSelectable = true;
                if(this.selWaiting.includes(v.id)) { this.listGroup.liste[i]['etat'] = 'en cours, '; isSelectable = false; }
                if(this.selSent.includes(v.id)) { this.listGroup.liste[i]['etat'] += 'envoyé'; isSelectable = false; }
                this.listGroup.liste[i]['isSelectable'] = isSelectable;
            })
            this.dialog = true
        },

        closeDialog() {
            this.$store.dispatch('removeAllGedFile');
            this.dialog = false
            this.params.selectedMail = {}
            this.selWaiting = []
            this.selSent = []
            this.selectedGroup = []
            this.removeOrAllowAll = false
            this.listGroup.liste.forEach((v,i) => {
                this.listGroup.liste[i]['etat'] = '';
                this.listGroup.liste[i]['isSelectable'] = true;
            })
        },

        includePJFile(attach,isinclude) {
            this.params.includeAttachement[this.params.selectedMail.id][attach.mod] = isinclude;
        }


    },
    mounted() {
        this.initiateParams()
        this.getModule()
        

    },

    created() {

    }

}

</script>
    
    
<style>
.cropper {
    height: 500px;
    background: #DDD;
}
</style>
        