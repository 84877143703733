<template>


    <div v-if="(loaded && prop.show)"> 

        <v-dialog
            v-model="dialog"
            max-width="500px"
            @click:outside="closeDialog"
            >

            <v-card>
                <v-card-text>  

                    <div v-for="(field,i) in fields" :key="i">

                        <component 
                        v-if="field.type!='BDD' && field.editable!==false"
                        :is="getFieldComponent(field.type)" 
                        :item="{name:field.name,value:field.key,liste:field.ArrayValue,otherparams:field.otherparams,
                        defaultprops:(!field.defaultprops) ? {show:true,description:field.description,persistenthint:true,multiple:field.multiple} : field.defaultprops }" 
                        :keyup="(item,value) => {changeArrayTable(item,value,false,field)}"
                        :change="(item,value,selectedKey) => {changeArrayTable(item,value,selectedKey,field)}"
                        :page="page"
                        :type="type"
                        :valField="Create[field.key]"
                        />

                        <div v-if="field.type=='BDD'">
                            <!--
                            <component 
                            :is="getFieldComponent(getSpecificField(field.field).format)" 
                            :item="formatItem(field,getSpecificField(field.field))" 
                            :keyup="changeArrayTable" 
                            :change="changeArrayTable"
                            :page="page"
                            :type="type"
                            :valField="Create[field.key]"
                            /> 
                            -->
                            <fromBDD 
                            :item="{fromBDD:field.field,defaultprops:{show:true}}"
                            :valField="Create[field.key]"
                            :change="(item,value) => { changeArrayTable(item,value) }"
                            /> 
                        </div>


                    </div>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text
                    @click="closeDialog" 
                    >
                    fermer
                </v-btn>
                <v-btn color="blue darken-1" text
                    @click="apply" 
                    >
                    Appliquer
                </v-btn>
                </v-card-actions>
            </v-card> 

    </v-dialog>

        <v-btn @click="dialog = true" v-if="prop.tableAdd!==false">
            <v-icon v-if="prop.addIcon">{{prop.addIcon}}</v-icon>
            {{prop.addTitle}}
            </v-btn>   
            
                <Table
                :Headers="Headers"
                :datas="model"
                :editTable="actions"
                @recepVal="changeModel"
                :isDraggable="prop.tableDraggable"
                :del="prop.tableDel"
                :hidesearch="prop.hidesearch"
                :hidefooter="prop.hideFooter"
                :item="item"
                />

    </div>
</template>
    
    
    
    <script>
import Table from '../engine/outils/simpleTable'  
import mixinFields from '../../mixins/Fields'
import fromBDD from './fromBDD.vue'

        export default {
            name : 'arrayTable',

            mixins : [mixinFields],
            // item : array du champ avec tous ces parametres.
            // keyup : function provenant de search ou FormComponent
            components : {
                Table,
                fromBDD
            },            

            data: function() {
                return { 
    
                    model : [],
                    defaultArray : {},
                    Headers : [],
                    fields :[],
                    arrayFieldsInDb : [],
                    fieldsInDB : [],
                    Create : {},
                    dialog : false,
                    editedIndex : -1   ,
                    loaded : false,
                    actions : [{ icon: 'mdi-pencil', action: this.editItem }],              
    
    
                }
            },
    
            watch : { 
                'item.otherparams.arrayTable' : {
                    handler() { 
                        this.constructArrayTable()

                    }, deep:true                   
                }
            },
    
            methods : {

                getSpecificField(id) {
                    return this.fieldsInDB.find((field) => field.id === id)
                },

                formatItem(field,originalItem) {
                    let defaultprosp = Object.assign( {}, originalItem.defaultprops, field.defaultprops);

                    var array = {value:field.key,defaultprops:defaultprosp};
                    
                    if(field.name!='') array['name'] = field.name;
                    let ee = Object.assign( {}, originalItem, array);
                    return ee;
                    

                },
    
                apply() {
                    
                    if (this.editedIndex > -1) {                   
                        this.$set(this.model, this.editedIndex, this.Create)
                    } else {
                        this.model.push(this.Create)
                    }

                this.change(this.item,this.model,this.editedIndex)
                    this.closeDialog();

                },
    
                closeDialog() {
                    this.Create = Object.assign({}, this.defaultArray);
                    this.editedIndex = -1
                    this.dialog = false;
                },

                editItem(item) {
                    

                    this.editedIndex = this.model.findIndex(el => JSON.stringify(el) === JSON.stringify(item))
                    this.Create = Object.assign({}, this.model[this.editedIndex]);
                    this.dialog = true
                },

                changeModel(data) {
                    this.model = data 
                    this.change(this.item,this.model)
                },

                changeArrayTable(item,value,selectedKey,thisField) {
                    if(thisField.change) thisField.change(item,value,selectedKey)
                    var realValue = (typeof value == 'object' && selectedKey && thisField.type!='arraytable') ? value[selectedKey] : value;
                    this.Create[item.value] = realValue
                },

                constructArrayTable() {
                    this.defaultArray = {}
                    this.Headers = []
                    this.fields = []
                    this.item.otherparams.arrayTable.forEach((v) => {
                    this.defaultArray[v.key] = ''
                    this.Headers.push({text:v.name,value:v.key});
                    this.fields.push(v);

                        // on récupère les champs dans la DB
                        if(v.type=='BDD') {
                            this.arrayFieldsInDb.push(v.field)
                        }
                    })
                    
                if(this.arrayFieldsInDb.length==0) this.loaded = true
                if(this.arrayFieldsInDb.length!=0) this.getListField({filter:{id:this.arrayFieldsInDb},typeformat:'form'}).then((resp) => {    
                            this.fieldsInDB = resp;   
                            this.loaded = true                        
                            }
                    
                        );

                }

            },
        
            mounted() {           
    
               this.model = (!Array.isArray(this.valField)) ? [] : this.valField;   

               this.Create = Object.assign({}, this.defaultArray);

            },


            created() {

                this.constructArrayTable();

            // permet d'ajouter des actions:
                if(this.item.actions) {
                    this.actions = [...this.actions, ...this.item.actions]
                }
            }
    
        }
    
    </script>